import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from "./store/store.js";
import { fairyDustCursor } from "cursor-effects";
const root = ReactDOM.createRoot(document.getElementById('root'));
new fairyDustCursor({
  colors: ["#ff0000", "#00ff00", "#0000ff","#ff0080", "#00ffff"],
});
root.render(
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
);

