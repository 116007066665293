import React from 'react'
import { Nav, NavLink, NavbarContainer, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { CloseOutlined } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import MainLogo from '../../assets/images/main-logo.png';
import MainLogo2 from '../../assets/images/main-logo2.png';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useDispatch, useSelector } from 'react-redux';
import { selectDarkMode, toggleDarkMode } from '../../slices/darkModeSlice';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const darkMode = useSelector(selectDarkMode);
  const dispatch = useDispatch();
  const theme = useTheme()
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('about-me');
                if (contactUsSection) {
                  const componentHeight = 100;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            {darkMode
              ? <img src={MainLogo} alt="logo" style={{ width: "70px", height: "70px", }} />
              : <img src={MainLogo2} alt="logo" style={{ width: "75px", height: "65px", }} />
            }
          </div>
        </NavLogo>
        <MobileIcon>
        {darkMode ? <Brightness7Icon onClick={() => dispatch(toggleDarkMode())} sx={{ color: theme.text_primary, cursor: 'pointer' }} /> : <Brightness4Icon onClick={() => dispatch(toggleDarkMode())} sx={{ color: theme.text_primary, cursor: 'pointer' }} />}
          {isOpen ?
            <CloseOutlined onClick={() => setIsOpen(!isOpen)} /> :
            <FaBars onClick={() => setIsOpen(!isOpen)} />}
        </MobileIcon>
        <NavItems>
          <NavLink href="#about-me"
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('about-me');
                if (contactUsSection) {
                  const componentHeight = 100;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            About
          </NavLink>
          <NavLink href='#skills-details'
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('skills-details');
                if (contactUsSection) {
                  const componentHeight = 80;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            Skills
          </NavLink>
          <NavLink href='#experience-details'
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('experience-details');
                if (contactUsSection) {
                  const componentHeight = -50;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            Experience
          </NavLink>
          <NavLink href='#projects-details'
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('projects-details');
                if (contactUsSection) {
                  const componentHeight = 70;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            Projects
          </NavLink>
          <NavLink href='#education-details'
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('education-details');
                if (contactUsSection) {
                  const componentHeight = 30;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            Education
          </NavLink>
          <NavLink href='#contact-me'
            onClick={() =>
              setTimeout(() => {
                const contactUsSection = document.getElementById('contact-me');
                if (contactUsSection) {
                  const componentHeight = 60;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0)}>
            Contact
          </NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.github} target="_blank">Github Profile</GitHubButton>
        </ButtonContainer>
        <ButtonContainer>
          {darkMode ? <Brightness7Icon onClick={() => dispatch(toggleDarkMode())} sx={{ color: theme.text_primary, cursor: 'pointer' }} /> : <Brightness4Icon onClick={() => dispatch(toggleDarkMode())} sx={{ color: theme.text_primary, cursor: 'pointer' }} />}
        </ButtonContainer>
        {
          isOpen &&
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="#about-me" onClick={() => {
              setIsOpen(!isOpen)
              setTimeout(() => {
                const contactUsSection = document.getElementById('about-me');
                if (contactUsSection) {
                  const componentHeight = 50;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0);
            }}>
              About
            </MobileLink>
            <MobileLink href='#skills-details' onClick={() => {
              setIsOpen(!isOpen);
              setTimeout(() => {
                const contactUsSection = document.getElementById('skills-details');
                if (contactUsSection) {
                  const componentHeight = 90;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0);
            }}>
              Skills
            </MobileLink>
            <MobileLink href='#experience-details' onClick={() => {
              setIsOpen(!isOpen);
              setTimeout(() => {
                const contactUsSection = document.getElementById('experience-details');
                if (contactUsSection) {
                  const componentHeight = 5;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0);
            }}>
              Experience
            </MobileLink>
            <MobileLink href='#projects-details' onClick={() => {
              setIsOpen(!isOpen);
              setTimeout(() => {
                const contactUsSection = document.getElementById('projects-details');
                if (contactUsSection) {
                  const componentHeight = 70;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0);
            }}>
              Projects
            </MobileLink>

            <MobileLink href='#education-details' onClick={() => {
              setIsOpen(!isOpen);
              setTimeout(() => {
                const contactUsSection = document.getElementById('education-details');
                if (contactUsSection) {
                  const componentHeight = 40;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'auto' });
                }
              }, 0);
            }}>
              Education
            </MobileLink>

            <MobileLink href='#contact-me' onClick={() => {
              setIsOpen(!isOpen);
              setTimeout(() => {
                const contactUsSection = document.getElementById('contact-me');
                if (contactUsSection) {
                  const componentHeight = 70;
                  const scrollOffset = contactUsSection.offsetTop - componentHeight;
                  window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                }
              }, 0);
            }}>
              Contact
            </MobileLink>

            <GitHubButton style={{ padding: '10px 16px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.github} target="_blank">Github Profile</GitHubButton>
          </MobileMenu>
        }
      </NavbarContainer>
    </Nav >
  )
}

export default Navbar